import React from "react";

type Palette = {
    primary: React.CSSProperties['color'],
    secondary: React.CSSProperties['color'],
    tertiary: React.CSSProperties['color'],
    quaternary: React.CSSProperties['color'],
    quinary: React.CSSProperties['color'],
}

const Complementary: Palette = {
    primary: '#000000',
    secondary: '#805959',
    tertiary: '#cccccc',
    quaternary: '#9195ed',
    quinary: '#305ab8'
}

const Greens: Palette = {
    primary: '#0a360d',
    secondary: '#e1fae3',
    tertiary: '#2be037',
    quaternary: '#56a35c',
    quinary: '#22b32c',
}

const Blues: Palette = {
    primary: '#090836',
    secondary: '#e1e1fa',
    tertiary: '#2522e0',
    quaternary: '#5150a3',
    quinary: '#1d1bb3',
}

const Reds: Palette = {
    primary: '#361104',
    secondary: '#fae8e1',
    tertiary: '#e0460f',
    quaternary: '#a35d43',
    quinary: '#b3390c',
}


export type Hue = 'Blues' | 'Reds' | 'Greens'

const Spread = {'Greens': Greens, 'Blues': Blues, 'Reds': Reds}

export default {Complementary, Spread};
